import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueI18n from 'vue-i18n'
import lang from './plugins/languages'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


Vue.config.productionTip = false;

Vue.use(VueI18n)

const messages = Object.assign(lang)
export const i18n = new VueI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages
})

Vue.use(VueSkycons, {
  color: "#1e88e5",
});

Vue.use(Vuebar);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  rtl: false
});

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
